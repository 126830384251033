import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,[_c(VCol,[_c(VCard,[_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":_vm.loadingUsers ? 11 : 12}},[_c(VSelect,{attrs:{"items":_vm.users,"item-value":"id","label":_vm.$t('UserPage.LinkToUser'),"return-object":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c(VRow,[_c(VCol,{attrs:{"cols":"6","xl":"6","md":"6","sm":"6"}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c(VCol,{attrs:{"cols":"6","xl":"6","md":"6","sm":"6"}},[_vm._v(" "+_vm._s(_vm.$t(("UserPage.Roles." + (item.role))))+" ")])],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c(VRow,[_c(VCol,{attrs:{"cols":"6","xl":"6","md":"6","sm":"6"}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c(VCol,{attrs:{"cols":"6","xl":"6","md":"6","sm":"6"}},[_vm._v(" "+_vm._s(_vm.$t(("UserPage.Roles." + (item.role))))+" ")])],1)]}}]),model:{value:(_vm.selectedUser),callback:function ($$v) {_vm.selectedUser=$$v},expression:"selectedUser"}})],1),(_vm.loadingUsers)?_c(VCol,{attrs:{"cols":"1","xl":"1","md":"1","sm":"1"}},[_c(VProgressCircular,{staticClass:"mt-5",attrs:{"indeterminate":"","color":"primary","width":2,"size":20}})],1):_vm._e()],1),_c(VRow,[_c(VCol,{attrs:{"cols":_vm.loadingRoles ? 11 : 12}},[_c(VSelect,{attrs:{"items":_vm.roles,"label":_vm.$t('UserPage.Role'),"item-text":_vm.$i18n.locale === 'ru' ? 'nameRu' : 'nameEn',"item-value":"id","return-object":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c(VRow,[_c(VCol,{attrs:{"cols":"6","xl":"6","md":"6","sm":"6"}},[_vm._v(" "+_vm._s(_vm.$t(("UserPage.Roles." + (item.id))))+" ")])],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c(VRow,[_c(VCol,{attrs:{"cols":"6","xl":"6","md":"6","sm":"6"}},[_vm._v(" "+_vm._s(_vm.$t(("UserPage.Roles." + (item.id))))+" ")])],1)]}}]),model:{value:(_vm.selectedRole),callback:function ($$v) {_vm.selectedRole=$$v},expression:"selectedRole"}})],1),(_vm.loadingRoles)?_c(VCol,{attrs:{"cols":"1","xl":"1","md":"1","sm":"1"}},[_c(VProgressCircular,{staticClass:"mt-5",attrs:{"indeterminate":"","color":"primary","width":2,"size":20}})],1):_vm._e()],1),_c(VRow,[_c(VCol,[_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.getData}},[_vm._v(" "+_vm._s(_vm.$t('BtnSearch'))+" ")])],1)],1)],1)],1)],1)],1),_c(VRow,[_c(VCol,[_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.dataFromDb,"item-key":"userId","search":_vm.search,"loading":_vm.loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c(VTextField,{staticClass:"mx-4",attrs:{"label":_vm.$t('LblSearch')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])]},proxy:true},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$t(("UserPage.Roles." + (item.roleName)))))])]}},{key:"item.checked",fn:function(ref){
var item = ref.item;
return [_c(VCheckbox,{attrs:{"disabled":_vm.selectedUser.role === 'ROOT',"value":item.userId},on:{"change":function($event){_vm.saveAllowed = true}},model:{value:(_vm.linkedUsers),callback:function ($$v) {_vm.linkedUsers=$$v},expression:"linkedUsers"}})]}}])})],1)],1),_c(VRow,[_c(VCol,[_c(VBtn,{staticClass:"mr-4",attrs:{"disabled":!_vm.saveAllowed,"color":"primary"},on:{"click":function($event){return _vm.saveUsers()}}},[_vm._v(" "+_vm._s(_vm.$t('UserPage.Save'))+" ")])],1)],1),_c(VSnackbar,{attrs:{"timeout":_vm.timeout,"color":_vm.color},model:{value:(_vm.showMessage),callback:function ($$v) {_vm.showMessage=$$v},expression:"showMessage"}},[_c('div',[_vm._v(" "+_vm._s(_vm.messageText)+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }