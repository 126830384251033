<template>
  <div>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col
                :cols="loadingUsers ? 11 : 12"
              >
                <v-select
                  v-model="selectedUser"
                  :items="users"
                  item-value="id"
                  :label="$t('UserPage.LinkToUser')"
                  return-object
                >
                  <template v-slot:item="{item}">
                    <v-row>
                      <v-col
                        cols="6"
                        xl="6"
                        md="6"
                        sm="6"
                      >
                        {{ item.name }}
                      </v-col>
                      <v-col
                        cols="6"
                        xl="6"
                        md="6"
                        sm="6"
                      >
                        {{ $t(`UserPage.Roles.${item.role}`) }}
                      </v-col>
                    </v-row>
                  </template>
                  <template v-slot:selection="{item}">
                    <v-row>
                      <v-col
                        cols="6"
                        xl="6"
                        md="6"
                        sm="6"
                      >
                        {{ item.name }}
                      </v-col>
                      <v-col
                        cols="6"
                        xl="6"
                        md="6"
                        sm="6"
                      >
                        {{ $t(`UserPage.Roles.${item.role}`) }}
                      </v-col>
                    </v-row>
                  </template>
                </v-select>
              </v-col>
              <v-col
                v-if="loadingUsers"
                cols="1"
                xl="1"
                md="1"
                sm="1"
              >
                <v-progress-circular

                  class="mt-5"
                  indeterminate
                  color="primary"
                  :width="2"
                  :size="20"
                ></v-progress-circular>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                :cols="loadingRoles ? 11 : 12"
              >
                <v-select
                  v-model="selectedRole"
                  :items="roles"
                  :label="$t('UserPage.Role')"
                  :item-text="$i18n.locale === 'ru' ? 'nameRu' : 'nameEn'"
                  item-value="id"
                  return-object
                >
                  <template v-slot:item="{item}">
                    <v-row>
                      <v-col
                        cols="6"
                        xl="6"
                        md="6"
                        sm="6"
                      >
                        {{ $t(`UserPage.Roles.${item.id}`) }}
                      </v-col>
                    </v-row>
                  </template>
                  <template v-slot:selection="{item}">
                    <v-row>
                      <v-col
                        cols="6"
                        xl="6"
                        md="6"
                        sm="6"
                      >
                        {{ $t(`UserPage.Roles.${item.id}`) }}
                      </v-col>
                    </v-row>
                  </template>
                </v-select>
              </v-col>
              <v-col
                v-if="loadingRoles"
                cols="1"
                xl="1"
                md="1"
                sm="1"
              >
                <v-progress-circular

                  class="mt-5"
                  indeterminate
                  color="primary"
                  :width="2"
                  :size="20"
                ></v-progress-circular>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  color="primary"
                  @click="getData"
                >
                  {{ $t('BtnSearch') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="dataFromDb"
          item-key="userId"
          class="elevation-1"
          :search="search"
          :loading="loading"
        >
          <template
            v-slot:top
          >
            <div class="row">
              <div class="col-12">
                <v-text-field
                  v-model="search"
                  :label="$t('LblSearch')"
                  class="mx-4"
                ></v-text-field>
              </div>
            </div>
          </template>

          <template v-slot:item.role="{item}">
            <span>{{ $t(`UserPage.Roles.${item.roleName}`) }}</span>
          </template>

          <template v-slot:item.checked="{item}">
            <v-checkbox
              v-model="linkedUsers"
              :disabled="selectedUser.role === 'ROOT'"
              :value="item.userId"
              @change="saveAllowed = true"
            ></v-checkbox>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          :disabled="!saveAllowed"
          color="primary"
          class="mr-4"
          @click="saveUsers()"
        >
          {{ $t('UserPage.Save') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="showMessage"
      :timeout="timeout"
      :color="color"
    >
      <div>
        {{ messageText }}
      </div>
    </v-snackbar>
  </div>
</template>
<script>
// eslint-disable-next-line object-curly-newline
import { mdiPencil, mdiLinkVariant, mdiTrashCanOutline, mdiDotsVertical } from '@mdi/js'
import qs from 'qs'

export default {
  data() {
    return {
      dataFromDb: [],
      showMessage: false,
      timeout: 5000,
      color: 'success',
      messageText: '',
      saveAllowed: false,
      dialog: false,
      selectedRole: {},
      roles: [],
      selectedUser: {},
      users: [],
      search: '',
      loadingUsers: false,
      loadingRoles: false,
      userId: Number(this.$route.params.user_id),
      linkedUsers: [],
      loading: false,
      icons: {
        mdiPencil,
        mdiLinkVariant,
        mdiTrashCanOutline,
        mdiDotsVertical,
      },
      userToDelete: 0,
    }
  },
  computed: {
    headers() {
      return [
        { text: '', value: 'checked' },
        { text: 'ID', value: 'userId' },
        { text: this.$t('UserPage.UserName'), value: 'userName' },
        { text: this.$t('UserPage.Email'), value: 'email' },
        { text: this.$t('UserPage.Role'), value: 'role' },
      ]
    },
  },
  watch: {
    selectedUser() {
      this.getRoles()
      this.dataFromDb = []
      this.saveAllowed = false
    },
    selectedRole() {
      this.dataFromDb = []
      this.saveAllowed = false
    },
  },
  mounted() {
    this.getUsers()
  },
  methods: {
    goToUserCreatePage() {
      this.$router.push({ name: 'user-create' })

      // this.$router.push({ name: 'campaigns-list', params: { advertiser_id: advertiserId } })
    },
    getUsers() {
      this.loadingUsers = true
      const params = {
        params: {
          userId: this.$getUserId(),
        },
        paramsSerializer: param => qs.stringify(param, { arrayFormat: 'repeat' }),
      }

      // this.$http.get('/advertisers/table_data', params) `${this.$apisBaseURL}/publisher/item`
      this.$http.get(`${this.$apiBaseURL}/user/link`, params).then(response => {
        this.users = response.data !== null ? response.data : []
        if (this.users.length > 0) {
          if (this.userId) {
            this.selectedUser = this.users.find(c => c.id === this.userId)
          } else {
            // eslint-disable-next-line prefer-destructuring
            this.selectedUser = this.users[0]
          }
        }

        this.loadingUsers = false
      })
    },
    getRoles() {
      this.loadingRoles = true
      const params = {
        params: {
          userId: this.selectedUser.id,
        },
        paramsSerializer: param => qs.stringify(param, { arrayFormat: 'repeat' }),
      }

      // this.$http.get('/advertisers/table_data', params) `${this.$apisBaseURL}/publisher/item`
      this.$http.get(`${this.$apiBaseURL}/roles/for/link`, params).then(response => {
        this.roles = response.data !== null ? response.data : []
        if (this.roles.length > 0) {
          // eslint-disable-next-line prefer-destructuring
          this.selectedRole = this.roles[0]
        }

        this.loadingRoles = false
      })
    },
    getData() {
      this.loading = true
      const params = {
        params: {
          currentUserId: this.$getUserId(),
          userId: this.selectedUser.id,
          roleName: this.selectedRole.name,
        },
        paramsSerializer: param => qs.stringify(param, { arrayFormat: 'repeat' }),
      }

      // this.$http.get('/advertisers/table_data', params) `${this.$apisBaseURL}/publisher/item`
      this.$http.get(`${this.$apiBaseURL}/user/link/items`, params).then(response => {
        this.dataFromDb = response.data !== null ? response.data : []
        this.linkedUsers = []
        if (this.dataFromDb.length > 0) {
          // eslint-disable-next-line prefer-destructuring
          this.dataFromDb.forEach(item => {
            if (item.belongs) {
              this.linkedUsers.push(item.userId)
            }
          })
        }

        this.loading = false
        this.saveAllowed = false
      })
    },
    saveUsers() {
      const params = {
        mainUserId: this.selectedUser.id,
        userIds: this.linkedUsers,
        roleId: this.selectedRole.id,
      }

      this.$http
        .put(`${this.$apiBaseURL}/user/link`, params)
        .then(() => {
          // тут сохраняем привязку.
          this.getData()
          this.messageText = this.$t('UserPage.SavedSuccesfully')
          this.showMessage = true
          this.saveAllowed = false
          this.color = 'success'
        })
        .catch(e => {
          this.messageText = e
          this.showMessage = true
          this.color = 'erroe'
        })
    },
  },
}
</script>
